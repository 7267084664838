import React from "react";
import { useQueryParam, StringParam } from "use-query-params";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
`;

const FeedbackTitle = styled.h1`
  margin: 8px;
`;

const FeedbackText = styled.p`
  margin: 8px;
`;

const FeedbackButton = styled.a`
  width: 200px;
  padding: 8px;
  margin: 8px;
  background-color: #3a36ff;
  color:  #fff;
  border-radius: 16px;
  font-size: 16px;
  text-decoration: none;
`;
const ResetPassword = (props) => {
    const [code, setCode] = useQueryParam('code', StringParam);
    const [email, setEmail] = useQueryParam('email', StringParam);

    if (!code || !email) {
		return (
			<>
				<Navbar />
				<FeedbackContainer>
					<FeedbackTitle>Error</FeedbackTitle>
					<FeedbackText>kapot</FeedbackText>
				</FeedbackContainer>
				<Footer />
			</>
		)
	}

  return (
    <>
      <Navbar />
      <FeedbackContainer>
        <FeedbackTitle>Reset Wachtwoord</FeedbackTitle>
        <FeedbackText>Klik op de button om je wacthwoord aan te passen</FeedbackText>
        <FeedbackButton href={`posgez://reset-password?code=${code}&email=${email}`}>Open App</FeedbackButton>
      </FeedbackContainer>
      <Footer />
    </>
  );
};

export default ResetPassword